
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) Gezinme çubuğunda Paylaş'a basın`,
's37a9e8aec5713460': `Daha Fazla`,
's5c6aad7a7e4a1437': `3) Ana Ekrana Ekle'ye basın`,
's6196153c4b0c1ea0': `Yükle`,
's8d0173763d91830c': `Bu sitenin uygulaması var. Daha iyi deneyim ve kolay erişim için Ana Ekranınıza ekleyin.`,
'sa5ef80b4bb9b39f8': `Küçült`,
'sc79fd7641eb9a975': `Talimatları gizle`,
'scdaf4bbff76674c8': `Ana Ekrana Ekle`,
'sdca23702207fba73': `Bu sitenin uygulaması var. Daha iyi deneyim ve kolay erişim için cihazınıza yükleyebilirsiniz.`,
'sdfe23506a3b0cdc8': `1) Safari tarayıcısında açın`,
    };
  