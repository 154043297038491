
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) Druk op delen in de navigatiebalk`,
's37a9e8aec5713460': `Meer`,
's5c6aad7a7e4a1437': `3) Druk op Toevoegen aan het startscherm`,
's6196153c4b0c1ea0': `Installeren`,
's8d0173763d91830c': `Deze site heeft app-functionaliteit. Voeg het toe aan uw startscherm voor uitgebreide ervaring en gemakkelijke toegang.`,
'sa5ef80b4bb9b39f8': `Minder`,
'sc79fd7641eb9a975': `Instructie verbergen`,
'scdaf4bbff76674c8': `Toevoegen aan het startscherm`,
'sdca23702207fba73': `Deze site heeft app-functionaliteit. Installeer het op uw apparaat voor uitgebreide ervaring en gemakkelijke toegang.`,
'sdfe23506a3b0cdc8': `1) Open in Safari-browser`,
    };
  