
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) Нажать "Поделиться"`,
's37a9e8aec5713460': `Подробнее`,
's5c6aad7a7e4a1437': `3) Нажать "На экран «Домой»"`,
's6196153c4b0c1ea0': `Установить`,
's8d0173763d91830c': `Этот веб-сайт имеет функционал приложения. Добавьте его на экран «Домой», чтобы получить дополнительные возможности и удобство использования.`,
'sa5ef80b4bb9b39f8': `Свернуть`,
'sc79fd7641eb9a975': `Спрятать Инструкцию`,
'scdaf4bbff76674c8': `На экран «Домой»`,
'sdca23702207fba73': `Этот веб-сайт имеет функционал приложения. Установите его, чтобы получить дополнительные возможности и удобство использования.`,
'sdfe23506a3b0cdc8': `1) Открыть в Safari браузере`,
    };
  